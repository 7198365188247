<script setup lang="ts">
const title = ref("Varga Szárnyas Kft");
const loaded = ref(false);
const { t, locale, setLocale } = useI18n();

const head = useLocaleHead({
  identifierAttribute: "id",
  addSeoAttributes: true,
});

/* useHead({
  meta: [{ content: title }],
  titleTemplate: (titleChunk) => {
    return titleChunk
      ? `${titleChunk} - Nuxt3 Typescript Free UI kit built with Vuetify3`
      : "Next-kit - Nuxt3 Typescript Free UI kit built with Vuetify3";
  },
}); */

useHead({
  title: "Varga Szárnyas Kft",
  htmlAttrs: {
    lang: locale.value,
  },
  meta: [
    ...(head.value.meta || []),
    {
      charset: "utf-8",
    },
    {
      property: "og:title",
      content: "Varga Szárnyas Kft",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      name: "og:site_name",
      content: "vargaszarnyas.hu",
    },
    {
      name: "og:url",
      content: "vargaszarnyas.hu",
    },
    {
      name: "og:description",
      content:
        "VARGA-SZÁRNYAS Baromfifeldolgozó Korlátolt Felelősségű Társaság, Varga Szárnyas Kft, varga, szarnyas, varga szárnyas, becsehely, baromfi, telephely, Baromfifeldolgozó",
    },
    {
      name: "description",
      content:
        "Varga-Szárnyas Baromfifeldolgozó Kft, 8866 Becsehely, Kossuth Lajos u. 254.",
    },
    {
      name: "keywords",
      content:
        "Varga Szarnyas, Varga Szarnyas Kft, Varga-Szárnyas Kft, Baromfifeldolgozó, Becsehely, Landing Page, Baromfi-feldolgozás, Baromfifeldolgozás, Poultry processing",
    },
    {
      name: "twitter:url",
      content: "https://vargaszarnyas.hu",
    },
    {
      name: "twitter:card",
      content: "website",
    },
    {
      hid: "author",
      name: "author",
      content: "kanyosoft.hu",
    },
  ],
  link: [
    ...(head.value.link || []),
    { rel: "icon", type: "image/x-icon", href: "/favicon.ico" },
  ],
});

onMounted(() => {
  setTimeout(() => {
    loaded.value = true;
  }, 100);
});
</script>

<template>
  <v-app>
    <v-layout>
      <v-main>
        <SectionHeaderHeader2 />
        <NuxtPage />
      </v-main>
    </v-layout>
  </v-app>
</template>

<style lang="scss">
// @import "@/assets/scss/style.scss";
.v-main {
  margin-top: 87px;
}
</style>
